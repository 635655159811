.navbar{
    transition: .5s ease;
}

.back{
    position: absolute;
    margin-left: 150px;
    color: rgb(255, 255, 255);

    .underline{
        height: 2px;
        width: 50%;
        background-color: var(--cor-secondary);
    }
}
.nav-container{
    margin: 0px 0px 0px 100px;
    padding: 0px 126px;
    width: 100%;
    display: flex;
}
.container-fluid{
    padding: 0px;
    display: flex;
}

.flex-content-nav {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 100px;

    .btn-danger {
        color: var(--cor-white);
    }
}

.navbar-expand-xl .navbar-nav .dropdown-menu{
    transform: translate(0px, 44px) !important;
}

.navbar-toggler {
    float: right;
}

.scrolled{
    background-color: #093490ea;
}

.offcanvas-body {
    display: flex;
    padding: 10px;
}

.flex-button-nav {
    display: flex;
}

.btn-group {
    padding: 0px 16px;
    transition: 0.2;
}
.btn-group .btn{
    padding: 8px;
    border: 1px solid #ffffff00;
}

.btn-group:hover .btn{
    color: var(--cor-secondary);
    border: 1px solid var(--cor-secondary);
    border-radius: 5px;
}

.navbar {
position: fixed;
top: 0;
width: 100%;

z-index: 9999;
}

.navbar-nav{
    gap: 50px;
}

.navLogo{
    filter: brightness(100);
    padding: 0px;
    margin: 0px;
    animation: slideToDown 1s .5s ease forwards;
    opacity: 0%;
}

a{
    color: white;
}

a.nav-link.active{
    color: white !important;
}
a.nav-link.active:hover{
    color: var(--cor-secondary) !important;
}

.btn-group .btn{
    background: none;

    color: white;
}
button{
    margin: 0px !important;
}
.nav-item{
    align-self: center;

    a{
        transition: .5s ease;
    }
}
.nav-item:hover{
    a{
        color: var(--cor-secondary);
    }
}

.aboutLink{
    opacity: 0%;
    animation: slideToDown 1s ease forwards;
}
.benefitsLink{
    padding: 0px;
    opacity: 0%;
    animation: slideToDown 1s .1s ease forwards;
}
.subscribeLink{
    padding: 0px;
    opacity: 0%;
    animation: slideToDown 1s .2s ease forwards;
}
.fleetLink{
    padding: 0px;
    opacity: 0%;
    animation: slideToDown 1s .3s ease forwards;
}

.navbar-toggler{
    border: none;
}
.navbar-toggler-icon{
    background-image: url('../../../../assets/icon/menu.png');
}

.navLogo2{
    filter: brightness(100);
    display: none;
}

@media screen and (max-width: 1700px){
    .nav-container{
        margin: 0px 0px 0px 0px !important;
    }
}

@media screen and (max-width: 1600px){
    .navbar{
        padding: 15px 30px;
        
        .navLogo{
            img{
                width: 200px;
            }
        }
    }
    .nav-container{
        margin: 0px;
        padding: 0px 20px;
    }
}

@media screen and (max-width: 1400px){
    .navbar{
        a, button{
            font-size: 15px;
        }
    }
    .back{
        margin-left: 20px;
    }
}

@media screen and (max-width: 1199px){
    .navbar-nav{
        gap: 20px;
    }
    .back{
        display: none;
    }
    .navbar{
        padding: 150px 5%;

        a{
            color: var(--cor-primary);
        }
    }
    .container-fluid{
        justify-content: space-between;
        padding: 0px;
        
        .navLogo2{
            display: block;
        }
    }
    .flex-content-nav{
        grid-template-columns: 100%;
        padding-top: 50px;
        display: grid;
        height: fit-content;
        row-gap: 20px;
    }
    .btn-group .btn{
        padding: 0px;
    }
    .nav-container{
        padding: 0px;
    }
    a.nav-link.active, .btn-group .btn{
        color: var(--cor-primary) !important;
    }
    .navLogo{
        display: none;
    }
    .nav-item{
        display: grid;
    }
    .navbar-toggler{
        padding: 0px;
    }
}

@media screen and (max-width: 425px){
    .container-fluid{
        padding: 0px;
    }
}