.sectionOneBg{
    display: grid;
    grid-template-areas: 'sectionOne';
    z-index: 200;
    position: relative;

    .arrow1{
        z-index: 0;
        grid-area: sectionOne;
        position: relative;
        margin: 100px auto auto 20px;
        opacity: 0%;
    }
    .arrow1.visible{
        animation: arrows 6s 1s ease infinite;
    }
    .arrow2{
        z-index: 0;
        grid-area: sectionOne;
        position: relative;
        margin: 100px auto auto 20px;
        opacity: 0%;
    }
    .arrow2.visible{
        animation: arrows 6s 2s ease infinite;
    }
    .arrow3{
        grid-area: sectionOne;
        position: relative;
        margin: 500px auto auto 500px;
        opacity: 0%;
    }
    .arrow3.visible{
        animation: arrows 3s 2s ease infinite;
    }
    .arrow4{
        grid-area: sectionOne;
        position: relative;
        margin: 500px auto auto 500px;
        opacity: 0%;
    }
    .arrow4.visible{
        animation: arrows 3s 3s ease infinite;
    }
    .arrow5{
        z-index: 1000;
        grid-area: sectionOne;
        position: relative;
        margin: 200px 810px auto auto;
        opacity: 0%;
    }
    .arrow5.visible{
        animation: arrows 3s 3s ease infinite;
    }
    .arrow6{
        z-index: 1000;
        grid-area: sectionOne;
        position: relative;
        margin: 200px 810px auto auto;
        opacity: 0%;
    }
    .arrow6.visible{
        animation: arrows 3s 4.2s ease infinite;
    }
    .arrow7{
        grid-area: sectionOne;
        position: relative;
        margin: 550px 50px auto auto;
        opacity: 0%;
    }
    .arrow7.visible{
        animation: arrows 3s 4s ease infinite;
    }
    .arrow8{
        grid-area: sectionOne;
        position: relative;
        margin: 550px 50px auto auto;
        opacity: 0%;
    }
    .arrow8.visible{
        animation: arrows 3s 5s ease infinite;
    }

}
.sectionOne{
    grid-area: sectionOne;
    height: 100vh;
    padding: 0px 150px;
    background-image: url('../../../../assets/img/home/hero/hero-bg.webp');
    display: flex;
    align-items: center;
    background-size: cover;

    .gptw{
        position: absolute;
        top: 40px;
        left: 50px;
        z-index: 1000;

        img{
            width: 60px !important;
            height: auto;
            opacity: 0%;
        }
    }
    .gptw.visible{
        img{
            animation: slideToDown 2s 1.5s ease forwards;
        }
    }

    .mySwiper{
        height: 100%;
        width: 100%;

        .swiper-slide-next, .swiper-slide-prev{
            opacity: 0%;
            animation: swiperPrev 1.5s ease forwards;
        }
        .swiper-slide-active{
            animation: swiperActive 1.5s ease forwards;
        }
        .swiper-slide{
            display: grid;
            align-items: center;

            h1{
                height: fit-content;
                color: white;
                margin-bottom: 100px;

                span{
                    color: var(--cor-secondary);
                    font-weight: 700;
                }
            }
        }
    }
    .swiperText{
        animation: halfToUp 1s ease forwards;
        h1{
            width: fit-content;
            justify-self: center;
        }
    }

    .img{
        animation: halfToUp 1.3s ease forwards;
        justify-self: center;
        height: auto;
        width: 650px;
    }
}

@media screen and (min-width: 2000px) {
    .sectionOne{
        img{
            width: 800px;
        }
    }
}

@media screen and (max-width: 1700px){
    .sectionOne{
        img{
            width: 450px;
        }
    }
}

@media screen and (max-width: 1600px){
    .sectionOneBg{
        .arrow1, .arrow2{
            width: 180px;
        }
        .arrow3, .arrow4{
            width: 180px;
            margin-top: 450px;
        }
        .arrow5, .arrow6{
            width: 70px !important;
            margin-right: 680px;
        }

        .arrow7, .arrow8{
            width: 180px;
            margin-top: 500px;
        }
    }
    .sectionOne{
        height: 100vh;
        background-size: 1600px;
        background-repeat: no-repeat;
        .swiper{
            .swiper-slide{
                height: fit-content;
                h1{
                    margin-bottom: 100px;
                }
            }
        }
        .img{
            width: 550px;
        }
        .gptw{
            top: 100px;
            left: 50px;
            z-index: 1000;
        }
        img{
            width: 450px;
            top: 120px;
        }
    }
}

@media screen and (max-width: 1440px){
    .sectionOneBg{
        height: 100vh;
        .arrow7, .arrow8{
            margin-bottom: 220px;
            margin-right: 0px;
            width: 200px;
        }
        .arrow3, .arrow4{
            width: 200px;
            margin-bottom: 290px;
        }
        .arrow1, .arrow2{
            width: 200px;
        }
        .arrow5, .arrow6{
            margin-right: 550px;
        }
        .arrow3, .arrow4{
            display: none;
        }
    }
    .sectionOne{
        padding: 150px 5% 0px 10%;

        .gptw{
           img{
                width: 50px !important;
            }
        }

        .img{
            width: 520px;
        }
    }
    
}

@media screen and (max-width: 1200px){
    .sectionOne{
        .img{
            width: 450px;
        }
    }
}

@media screen and (max-width: 1000px){
    .sectionOneBg{
        height: 95vh;
        .arrow1, .arrow2{
            margin-top: 200px;
        }
        .arrow5, .arrow6{
            margin-right: 400px;
        }
    }
    .sectionOne{
        padding: 150px 5%;
        height: 100vh;

        img{
            bottom: 300px;
            width: 400px;
        }
    }
}

@media screen and (max-width: 900px){
    .sectionOneBg{
        .arrow5, .arrow6{
            margin-bottom: 600px;
            margin-right: 320px;
        }
        .arrow7, .arrow8{
            margin-bottom: 230px;
        }
    }
    .sectionOne{
        padding-right: 0px;
        display: grid;
        grid-template-columns: 40% 60%;

        h1{
            height: fit-content;
            margin: 0px;
            top: 50px;
            width: 100%;
            font-size: 22px !important;
        }
        .img{
            width: 100%;
            max-width: 400px;
            justify-self: right;
        }
    }
}

@media screen and (max-width: 768px){
    .sectionOneBg{
        height: 100vh;
        .arrow1, .arrow2{
            display: none;
        }
        .arrow3,.arrow4{
            margin-left: 100px;
        }
        .arrow5, .arrow6{
            margin-top: 250px;
            margin-right: 100px;
        }
        .arrow7, .arrow8{
            margin: 100px 0px auto auto;
        }
    }
    .sectionOne{
        grid-template-columns: 100%;
        padding-bottom: 0px;
        height: 95vh;

        .gptw{
            left: auto;
            right: 37px;
        }
        .swiper{
            height: fit-content;
            .swiper-slide{
                justify-self: right;
                h1{
                    margin-bottom: 0px;
                    justify-self: start;
                }        
            }
        }
        .swiperImg{
            height: fit-content;
            top: -0px;
            right: 50px;
        }
    }
}

@media screen and (max-width: 550px){
    .sectionOneBg{
        height: 100vh;
        .arrow3, .arrow4{
            margin-left: 10px;
            margin-top: 400px;
        }
        .arrow5, .arrow6{
            margin-right: 20px;
        }
    }
    .sectionOne{
        .gptw{
            right: 27px;
            img{
                width: 40px !important;
            }
        }
        .swiperImg{
            right: 0px;
            
            .swiper-slide{
                img{
                    width: 350px;
                }
            }
        }
    }
}

@media screen and (max-width: 425px){
    .sectionOneBg{
        height: 92vh;
        .arrow5, .arrow6{
            margin: auto auto 320px 10px;
        }
    }
    .sectionOne{
        height: 90vh;
        .swiperImg{
            top: -20px;
        }
        .swiper{
            .swiper-slide{
                h1{
                    font-size: 20px !important;
                }
                img{
                    width: 85%;
                }
            }
        }
    }
}
