#landingPageTwo{
    .sectionFour{
        padding: 50px 150px 50px;
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        opacity: 0%;
        background-image: url('../../img/landingPageTwo/sectionFour/sectioFour-bg.webp');
        background-size: cover;
        border-radius: 30px;
    
        .sectionFourText{
            h1{
                color: var(--cor-primary);
                font-weight: 700;
                width: 400px;
                margin: 0px;
                opacity: 0%;
    
                span{
                    color: var(--cor-primary);
                }
            }
            h1.visible{
                animation: fadeIn 1s .2s ease forwards;
            }
    
            p{
                color: var(--cor-primary);
                margin-top: 20px;
                font-weight: 500;
                max-width: 600px;
                font-size: 20px;
            }
        }
        form{
            padding: 50px 20px;
            -webkit-backdrop-filter: blur(10.5px);
            backdrop-filter: blur(5px);
            background-color: #FF6700;
            box-shadow: 7px 10px 10px 2px rgba(0, 0, 0, 0.246);
            border-radius: 20px;
            width: 600px;
            justify-self: right;
            opacity: 0%;
            color: var(--cor-white);
            
            label{
                margin-bottom: 5px !important;
                color: var(--cor-white);
                font-weight: 500;
                font-size: 15px;
            }
            input, select{
                border: 1px solid #ff66006e;
                background-color: #d45500;
                margin-bottom: 10px;
                box-shadow: none !important;
                color: var(--cor-white);
                transition: .5s ease;
            }
            input:focus, select:focus{
                background-color: #002a72ab;
                color: white;
                border: 1px solid #ff66006e;
            }
    
            button{
                width: 100%;
                background-color: var(--cor-primary);
                border: none;
            }
            button:hover{
                background-color: var(--cor-tertiary);
            }
        }
        form.visible{
            animation: fadeIn 1s .5s ease forwards;
        }
    }
    .sectionFour.visible{
        animation: fadeIn 2s ease forwards;
    }
    
    @media screen and (max-width: 1600px) {
        .sectionFour{
            padding: 50px 100px;
    
            .sectionFourText{
                p{
                    font-size: 16px;
                }
            }
        }
    }
    
    @media screen and (max-width: 1400px) {
        .sectionFour{
            padding: 50px 100px;
            
            .sectionFourText{
                p{
                    width: 80%;
                }
            }
            form{
                width: 100%;
    
                label, input{
                    font-size: 14px;
                }
            }
        }
    }
    
    @media screen and (max-width: 1200px) {
        .sectionFour{    
            padding: 50px;

            form{
                max-width: 450px;
                width: 100%;
            }
        }
    }
    
    @media screen and (max-width: 900px) {
        .sectionFour{
            grid-template-columns: 100%;
    
            .sectionFourText{
                padding: 0px;
                
                p{
                    max-width: 600px;
                }
            }
            .contacts{
                display: flex;
                gap: 5%;
    
                .phone, .whats{
                    padding-top: 20px;
                }
            }
            form{
                margin-top: 50px;
                padding: 30px 20px;
                max-width: 450px;
                width: 100%;
            }
        }
    }
    
    @media screen and (max-width: 768px) {
        .sectionFour{
            padding-top: 80px;
            
            .contacts{
                gap: 0px;
                display: grid;
    
                .phone, .whats{
                    padding-top: 5px;
                }
            }
            form{
                margin-top: 20px;
            }
        }
    }
    
    @media screen and (max-width: 500px){
        .sectionFour{
            padding: 80px 20px 50px;
        }
    }
    
    @media screen and (max-width: 425px){
        .sectionFour{
            .sectionFourText{
                h1{
                    width: 100%;
                }
                p{
                    font-size: 14px;
                }
            }
            .contacts{
                img{
                    width: 20px !important;
                    height: 20px !important;
                }
    
                .address{
                    font-size: 14px !important;
                }
    
                .email, .phone, .whats{
                    p{
                        font-size: 14px !important;
                    }
                }
            }
            form{
                padding: 30px 10px;
                input, textarea{
                    border: 1px solid var(--cor-secondary);
                }
            }
        }
    }
}