#landingPageTwo{
    .sectionTwo {
        background-image: url("../../img/landingPageTwo/sectionTwo/sectionTwo-bg.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        padding: 0px 150px 0px;
        opacity: 0%;
        display: grid;
        height: 90vh;
        align-items: center;
        margin-top: -300px;
        position: relative;
        z-index: 1;
    
        .arrows1,
        .arrows2 {
            width: fit-content;
            position: absolute;
            display: grid;
            top: -50%;
            left: 10%;
            transform: translateY(500px);
    
            img {
                opacity: 0%;
            }
    
            .img1 {
                animation: arrows 2s ease infinite;
            }
    
            .img2 {
                animation: arrows 2s .3s ease infinite;
            }
    
            .img3 {
                animation: arrows 2s .6s ease infinite;
            }
    
            .img4 {
                animation: arrows 2s .9s ease infinite;
            }
        }
    
        .arrows1 {
            opacity: 50%;
        }
    
        .arrows2 {
            top: -20%;
            left: 25% !important;
        }
    
        .text{
            height: fit-content;
            align-self: center;
            margin-top: 150px;
    
            h1 {
                text-align: right;
                opacity: 0%;
                color: var(--cor-secondary);
                font-weight: 700;
                margin-bottom: 20px;
            }
        
            h1.visible {
                animation: halfToDown 1s .2s ease forwards;
            }
        
            p {
                text-align: right;
                justify-self: right;
                opacity: 0%;
                max-width: 1000px;
                font-size: 20px;
                color: var(--cor-tertiary);
                font-weight: 400;
                margin-bottom: 20px;
            }
        
            p.visible {
                animation: halfToDown 1s .2s ease forwards;
            }
    
            a{
                display: grid;
                height: fit-content;
                width: fit-content;
                opacity: 0%;
                justify-self: right;
        
                button{
                    width: 400px;
                    height: 60px;
                    background-color: var(--cor-secondary);
                    border: none;
                    border-radius: 10px;
                    color: white;
                    font-weight: 600;
                    transition: .4s ease;
                    border: 2px solid rgba(255, 255, 255, 0);
                }
            }
            a.visible{
                animation: halfToDown 1s .6s ease forwards;
            }
            a:hover{
                button{
                    background-color: rgba(255, 255, 255, 0.815);
                    color: var(--cor-secondary);
                    border: 2px solid var(--cor-secondary);
                    scale: .95;
                }
            }
        }
    }
    
    .sectionTwo.visible {
        animation: fadeIn 1s ease forwards;
    }
    
    @media screen and (max-width: 1600px) {
        .sectionTwo {
            padding: 0px 100px;

            .text{
                p {
                    font-size: 18px;
                }
            }
            a {
                button {
                    width: 350px;
                    height: 50px;
                }
            }
        }
    }
    @media screen and (max-height: 740px) {
        .sectionTwo{
            margin-top: -200px;
        }
    }
    @media screen and (max-height: 700px) {
        .sectionTwo{
            margin-top: -150px;
        }
    }
    
    @media screen and (max-width: 1440px) {
        .sectionTwo {
            .arrows1 {
                left: 2%;
    
                img {
                    width: 120px;
                }
            }
    
            .arrows2 {
                left: 12% !important;
    
                img {
                    width: 90px;
                }
            }
    
            p {
                max-width: 800px;
            }
        }
    }
    
    
    @media screen and (max-width: 1200px) {
        .sectionTwo {
            background-size: 1600px;
            padding: 0px 50px;
    
            .arrows1 {
                img {
                    width: 100px;
                }
    
                top: -400px;
            }
    
            .arrows2 {
                top: -150px;
    
                img {
                    width: 80px;
                }
            }
        }
    }
    
    @media screen and (max-width: 900px) {
        .sectionTwo {
            background-size: 1800px;
    
            .arrows1 {
                top: -470px;
            }
    
            .arrows2 {
                img {
                    width: 60px;
                }
    
                right: 20px !important;
                left: auto !important;
                top: -50px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .sectionTwo {
            .text{
                p{
                    font-size: 16px;
                }

                a{
                    button{
                        width: 300px;
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 500px) {
        .sectionTwo {
            padding: 200px 20px 120px;
            background-size: 1800px;
    
            .arrows1 {
                display: none;
            }
    
            .arrows2 {
                top: -70px;
            }
    
            p {
                font-size: 16px;
            }
    
            a {
                button {
                    width: 350px;
                    height: 50px;
                }
            }
        }
    }
    
    @media screen and (max-width: 425px) {
        .sectionTwo {
            padding: 100px 20px 60px;
            background-size: 1600px;
    
            .arrows2 {
                top: -170px;
            }
    
            p {
                font-size: 14px;
                width: 100%;
            }
    
            h1 {
                width: 100%;
                max-width: 100%;
            }
    
            a {
                width: 100%;
    
                button {
                    width: 100%;
                }
            }
        }
    }
}