#landingPageOne{
    .sectionFive {
        padding: 100px 0px;
        background-image: url('../../../../assets/img/fleetStructure/fleetFour/fleetFour-bg.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100%;
        opacity: 0%;
        transition: .5s ease;
    
        .sectionFiveTitle {
            text-align: center;
            display: grid;
            justify-content: center;
    
            h1 {
                justify-self: center;
                font-weight: 700;
                color: var(--cor-white);
                width: 100%;
                opacity: 0%;
            }
    
            h1.visible {
                animation: slideToUp 1s ease forwards;
            }
    
            span {
                color: var(--cor-secondary);
            }
    
            p{
                max-width: 800px;
                margin: 15px 0px 0px;
                font-weight: 300;
                color: rgb(236, 236, 236);
            }
        }
    
        .swiper {
            padding: 50px 150px;
            
            .swiper-button-prev:after,
            .swiper-button-next:after {
                display: none;
            }
    
            .swiper-button-next {
                right: 100px;
            }
    
            .swiper-button-prev {
                left: 100px;
            }
    
            .swiper-slide-active {
                .card {
                    opacity: 100% !important;
                }
            }
    
            .swiper-slide {
                .card {
                    display: grid;
                    grid-template-columns: auto auto !important;
                    align-items: center;
                    padding: 0px 80px;
                    border: none;
                    background-color: var(--cor-primary);
                    box-shadow: 7px 10px 10px 2px rgba(0, 0, 0, 0.246);
                    border-radius: 30px;
                    backdrop-filter: blur(5px);
                    transition: .5s ease;
                    opacity: 0%;
                    min-height: 60vh;
                    background-size: cover;
                    background-position: 50%;
    
                    .cardText {
                        padding-top: 30px;
                        display: grid;
                        height: fit-content;
                        align-self: center;
                        gap: 5px;
                        width: 70%;
    
                        h1 {
                            font-weight: 700;
                            color: var(--cor-secondary);
                            margin: 0px;
                            opacity: 0%;
                            font-size: 35px !important;
                        }
                        h1.visible {
                            animation: slideToUp 1s .4s ease forwards;
                        }
    
                        p {
                            width: 100%;
                            max-width: 600px;
                            line-height: 25px;
                            padding-top: 10px;
                            margin: 0px;
                            font-size: 25px;
                            font-weight: 600;
                            opacity: 0%;
                            color: rgb(240, 240, 240);
    
                            .seta {
                                margin: 0px 5px 5px;
                                width: 20px;
                                opacity: 100%;
                            }
                        }
                        p.visible {
                            animation: slideToUp 2s .8s ease forwards;
                        }
                    }
    
                    img {
                        justify-self: start;
                        opacity: 0%;
                        width: 800px;
                        max-width: 800px;
                    }
    
                    img.visible {
                        animation: slideToUp 2s .4s ease forwards;
                    }
                }
                
                .card1, .card3{
                    .cardText{
                        grid-column-start: 1;
                        grid-column-end: 2;
                    }
                }
                .card2, .card4{
                    grid-template-rows: 100%;
                    .cardText{
                        grid-column-start: 2;
                        grid-column-end: 3;
                        justify-self: end;
                    }
                    img{
                        grid-row-start: 1;
                        grid-row-end: 2;
                        grid-column-start: 1;
                        grid-column-end: 2;
                    }
                }
            }
        }
    }
    
    .sectionFive.visible {
        opacity: 100%;
    }
    
    @media screen and (max-width: 1600px) {
        .sectionFive {    
            .sectionFiveTitle {
                h1 {
                    width: 450px;
                }
            }
    
            .swiper {
                padding: 50px 100px;
                .swiper-slide {
                    .card {    
                        .cardText {
                            h1 {
                                font-size: 30px !important;
                            }    
                            p {
                                font-size: 22px;
                                line-height: 30px;
                            }
                        }
                        img{
                            width: 700px;
                        }
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 1400px) {
        .sectionFive {
            background-position: 0% 36%;
    
            .swiper {
    
                .swiper-button-next,
                .swiper-button-prev {
                    img {
                        width: 30px;
                    }
                }
    
                .swiper-slide {
                    .card {
                        padding: 0px 50px !important;
                        .cardText {
                            p {
                                line-height: 25px;
                            }
                        }
    
                        img {
                            width: 600px;
                        }
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 1200px) {
        .sectionFive {
            .swiper {
                padding: 50px 100px;
    
                .swiper-slide{
                    .card{
                        .cardText{
                            h1{
                                font-size: 25px !important;
                            }
                            p{
                                font-size: 18px;
                            }
                        }
                        img{
                            width: 500px;
                        }
                    }
                }
                .swiper-button-next {
                    right: 50px;
                }
    
                .swiper-button-prev {
                    left: 50px;
                }
            }
        }
    }
    
    @media screen and (max-width: 1000px) {
        .sectionFive {
            padding: 100px 20px;
            .swiper {
                padding: 50px 50px;

                .swiper-button-next {
                    right: 30px;
                }
    
                .swiper-button-prev {
                    left: 30px;
                }
    
                .swiper-slide {
                    .card {
                        padding: 50px 50px 0px !important;
                        grid-template-columns: 100% !important;
                        grid-template-rows: auto auto;
    
                        img{
                            justify-self: end;
                            grid-column-start: 1;
                            grid-column-end: 2;
                            grid-row-start: 2;
                            grid-row-end: 3;
                            justify-self: end;

                        }
                        .cardText {
                            justify-self: start;
                            max-width: 400px;
                            padding: 0px;
                            grid-column-start: 1;
                            grid-column-end: 2;
                            grid-row-start: 1;
                            grid-row-end: 2;

                            p {
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 768px) {
        .sectionFive{
            .swiper{
                .swiper-slide{
                    .card{
                        img{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .sectionFive {
            .sectionFiveTitle {
                padding: 0px 50px;
    
                h1 {
                    width: 100%;
                }
            }
    
            .swiper {
                padding: 20px;
    
                .swiper-button-next {
                    top: 55%;
                    right: 10px;
                }
    
                .swiper-button-prev {
                    top: 55%;
                    left: 10px;
                }
    
                .swiper-slide {
                    .card {
                        padding: 50px 20px !important;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 425px) {
        .sectionFive {
            padding: 20px 0px;
    
            .swiper {
    
                .swiper-button-prev,
                .swiper-button-next {
                    img {
                        width: 25px;
                    }
                }
    
                .swiper-slide {
                    .card {
                        padding-top: 40px;
                        border-radius: 10px;
    
                        .cardText {
                            h3 {
                                font-size: 18px;
                            }
    
                            h4 {
                                font-size: 16px;
                            }
    
                            p {
                                font-size: 14px;
                            }
                        }
                    }
    
                    .gheco {
                        img {
                            width: 100%;
                            justify-self: center;
                        }
                    }
                }
            }
        }
    }
}