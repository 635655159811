#landingPageTwo{
    
    .sectionThree {
        padding: 30px 0px 50px;
        display: grid;
        background-image: url("../../../../assets/img/esg/esgSeven/esgSeven-bg.webp");
        background-repeat: no-repeat;
        background-position: 106% 100%;
        background-size: 350px;
        position: relative;

        .arrow1{
            position: absolute;
            inset: 0px auto auto 100px;
            opacity: 0%;
        }
        .arrow1.visible{
            animation: arrows 3s 4s ease infinite;
        }
        .arrow2{
            position: absolute;
            inset: 0px auto auto 100px;
            opacity: 0%;
        }
        .arrow2.visible{
            animation: arrows 3s 5s ease infinite;
        }
    
        .sectionThreeTitle {
            text-align: center;
            justify-self: center;
            display: grid;
    
            h1 {
                opacity: 0%;
                justify-self: center;
                font-weight: 700;
                color: var(--cor-secondary);
                width: 600px;
    
                span {
                    color: var(--cor-primary);
                }
            }
            h1.visible {
                animation: halfToUp 1s .1s ease forwards;
            }
    
            p {
                opacity: 0%;
                margin: 0px;
                padding-top: 10px;
                line-height: 30px;
                font-size: 18px;
                width: 1000px;
                font-weight: 500;
                color: var(--cor-tertiary);
            }
            p.visible {
                animation: halfToUp 1s .2s ease forwards;
            }
        }
    
        .swiper {
            max-width: 85%;
            padding: 50px 15px;
    
            .swiper-slide {
                transition: .5s ease;
                cursor: pointer;
            }
    
            .swiper-slide:hover {
                transform: translateY(-30px);
    
                .card {
                    box-shadow: none;
                    border: 1px solid var(--cor-secondary);
                }
            }
    
            .card {
                padding: 50px;
                border: none;
                border-radius: 20px;
                -webkit-backdrop-filter: blur(10.5px);
                backdrop-filter: blur(5px);
                background-color: var(--cor-secondary);
                width: 100%;
                height: 100%;
                box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.267);
                opacity: 0%;
                border: 1px solid rgba(255, 255, 255, 0);
                transition: .5s ease;
                text-align: center;
                align-items: center;
                gap: 20px;
    
                img {
                    margin-top: 20px;
                    width: 100px;
                    justify-self: center;
                }
    
                p {
                    font-size: 18px;
                    color: var(--cor-white);
                    font-weight: 600;
                    margin: 0px;
                }
            }
    
            .card.visible {
                animation: fadeIn 1s ease forwards;
            }
            .card:hover{
                background-color: #001d4ecc;
            }
        }
    }
    
    @media screen and (max-width: 1600px) {
        .sectionThree {
            padding: 50px 0px 0px;
            background-position: 100% 100%;
            background-size: 300px;
    
            .arrow1{
                width: 200px;
                inset: 0px auto auto 50px;
            }
            .arrow2{
                width: 200px;
                inset: 0px auto auto 50px;
            }

            .sectionThreeTitle {
                p {
                    font-size: 16px;
                }
            }
    
            .swiper {
                max-width: 90%;
            }
        }
    }
    
    @media screen and (max-width: 1400px) {
        .sectionThree {
            background-size: 250px;
    
            .arrow1{
                width: 200px;
                inset: 0px auto auto -40px;
            }
            .arrow2{
                width: 200px;
                inset: 0px auto auto -40px;
            }

            .swiper {
                max-width: 100%;
    
                .card {
                    p {
                        font-size: 15px;
                        margin: 0px;
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 1000px) {
        .sectionThree {
            background-size: 250px;
    
            .sectionThreeTitle {
                padding: 0px 50px;
    
                p {
                    width: 100%;
                }
            }
    
            .swiper {
                .card {
                    border-radius: 10px;
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
    
    @media screen and (max-width: 768px) {
        .sectionThree {
            background-position: 100% 100%;
            background-size: 200px;
    
            .arrow1{
                width: 200px;
                inset: -50px auto auto 0px;
            }
            .arrow2{
                width: 200px;
                inset: -50px auto auto 0px;
            }

            .sectionThreeTitle {
                h1 {
                    width: 100%;
                }
            }
        }
    }
    
    @media screen and (max-width: 550px) {
        .sectionThree {
            .arrow1, .arrow2{
                display: none;
            }
            .swiper {
                .card {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 425px) {
        .sectionThree {
            .sectionThreeTitle {
                padding: 0px 20px;
    
                p {
                    font-size: 14px;
                    padding-top: 20px;
                }
            }
        }
    }
}